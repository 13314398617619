import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import VerticalSlider from '../../VerticalSlider'
import TestimonialEntry from './components/TestimonialEntry'

import { colors, breakpoints, zIndexes } from '../../../../constants/style'
import Image from '../../Image'
import IconStar from '../../icons/IconStar'

const MIN_LOGO_WIDTH = 130

const TestimonialsSection = styled('section')`
  background: ${colors.titleDark};
  color: #fff;
  position: relative;
  overflow: hidden;

  .header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6.25rem;
    @media screen and (max-width: ${breakpoints.desktopLarge}) {
      margin-bottom: 5rem;
    }
    @media screen and (max-width: ${breakpoints.desktopSmall}) {
      flex-direction: column;
    }
  }

  .header-text {
    position: relative;
    z-index: ${zIndexes.testimonialsHeader};
    max-width: 48.25rem;
    margin-bottom: 3.5rem;
    ::before {
      content: '';
      display: block;
      margin-bottom: 3rem;
      width: 50px;
      height: 5px;
      background-color: #1bfdbf;
    }
  }

  .blur {
    background-color: #7b51e6;
    opacity: 0.5;
    filter: blur(400px);
    position: absolute;
    width: 638px;
    height: 383px;
    top: 55px;
    right: 5%;
    @media screen and (max-width: ${breakpoints.mobileLarge}) {
      display: none;
    }
  }

  .header-right {
    display: flex;
    justify-content: flex-start;
  }

  .testimonials-rating {
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .testimonials-score {
    font-size: 8.375rem;
    color: rgba(16, 7, 39);
    text-shadow: -1px 0 #a284f7, 0 1px #a284f7, 1px 0 #a284f7, 0 -1px #a284f7;
    font-weight: 700;
  }

  .testimonials-stars {
    margin-top: 0.8rem;
  }

  .columns {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .navigation {
    margin-bottom: 6.5625rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    column-gap: 5rem;
    width: 100%;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    &__position {
      font-family: "BioSans-SemiBold", sans-serif;
      font-size: 1rem;
      color: ${colors.primary};

      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 3px;
        margin-right: 0;
        vertical-align: middle;
        background: ${colors.primary};
        font-size: 0;
        line-height: 0;
        transition: width 0.2s ease-in-out, margin-right 0.2s ease-in-out;
      }
    }

    &__item {
      flex-shrink: 0;
      cursor: pointer;
      filter: contrast(0) saturate(0);
      opacity: 0.5;
      transition: all 0.2s;
      &__title {
        color: ${colors.mutedLight};
        font-size: 1.375rem;
      }

      &.active,
      &:hover {
        filter: saturate(1);
        opacity: 1;
      }

      &.active {
        .navigation__title {
          color: ${colors.blogText};
        }
      }
    }

    &__logo {
      height: 4.25rem;
    }
  }

  .testimonials {
    max-width: 65rem;
  }

  .testimonials-description {
    margin-bottom: 5.625rem;
  }

  .testimonials-paragraph {
    color: ${colors.grayLight};
    font-size: 1.1875rem;
    font-family: Roboto-Regular, sans-serif;
    @media screen and (max-width: ${breakpoints.desktopSmall}) {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    .navigation {
      margin-bottom: 5.3125rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .testimonials {
      max-width: 46rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .columns {
      flex-direction: column;
    }

    .testimonials {
      width: 100%;
      flex: 0 1 auto;
    }

    .navigation {
      margin-bottom: 5.625rem;

      &__logo {
        min-width: ${MIN_LOGO_WIDTH}px;
      }
    }
  }
`

const Testimonials = ({ header, testimonials }) => {
  const [position, setPosition] = useState(0)
  const navigationRef = useRef(null)

  const scrollToSlide = (index) => {
    const activeItemsList = [...navigationRef.current.querySelectorAll('.navigation__item')]

    activeItemsList[index].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
  }

  const handleSlideChange = (index) => {
    setPosition(index)

    // using delay in order to display the slider correctly in chrome for tablet/mobile
    setTimeout(() => {
      scrollToSlide(index)
    }, 0)
  }

  const sliderSettings = {
    adaptiveHeight: true,
  }

  const companyLogos = testimonials.map(({ companyLogo }) => companyLogo).filter(Boolean)

  return (
    <TestimonialsSection className="testimonials-section">
      <div className="container">
        <div className="blur"></div>
        {testimonials.length > 1 && (
          <div className="header-container">
            <div className="header">
              {Boolean(header) && <h2 className="header-text">{header}</h2>}
              <div className="testimonials-paragraph">
                We have 85+ reviews with an average score of
              </div>
            </div>
            <div className="header-right">
              <div className="testimonials-rating">
                <div className="testimonials-score">4.9</div>
                <div className="testimonials-stars">
                  <IconStar fill="#7E55F0" width="26" height="23" />
                  <IconStar fill="#7E55F0" width="26" height="23" />
                  <IconStar fill="#7E55F0" width="26" height="23" />
                  <IconStar fill="#7E55F0" width="26" height="23" />
                  <IconStar fill="#7E55F0" width="26" height="23" />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="columns">
          {companyLogos.length > 0 && (
            <div className="navigation" ref={navigationRef}>
              {testimonials.map(({ companyLogo }, index) => (
                <div
                  key={index}
                  className={`navigation__item ${index === position ? 'active' : ''}`}
                  onClick={() => setPosition(index)}
                >
                  <Image image={companyLogo} objectFit="contain" className="navigation__logo" />
                </div>
              ))}
            </div>
          )}

          <div className="testimonials dark">
            <VerticalSlider
              startPosition={position}
              onSlideChange={handleSlideChange}
              customSettings={sliderSettings}
              testimonial={testimonials[position]}
            >
              {testimonials.map(({ description, authorName, authorPosition }, index) => (
                <TestimonialEntry
                  key={index}
                  description={description}
                  authorName={authorName}
                  authorPosition={authorPosition}
                />
              ))}
            </VerticalSlider>
          </div>
        </div>
      </div>
    </TestimonialsSection>
  )
}

export default Testimonials
