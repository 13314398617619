import { FC, useEffect, useRef, useState } from 'react'
import { useMatch } from '@reach/router'
import classnames from 'classnames'
import { Link } from 'gatsby';

import { NavItem } from 'hooks/graphql/others'
import { ReactComponent as ChevronIcon } from 'static/images/icons/chevron.svg';
import IconArrow from 'components/icons/IconArrow';

import { MenuLink } from './MenuLink';
import styles from './MenuExpandable.module.scss'
import { RecommendationData } from './recommendationSection/types';
import { RecommendationArticles, RecommendationQuote, RecommendationReview } from './recommendationSection';

interface MenuItemProps {
  className?: string
  item: NavItem
}

interface MenuExpandableProps extends MenuItemProps {
  id: string
  isOpen: boolean
  setIsOpen: (id: string | null) => void
}

const MenuItemWithDescription: FC<MenuItemProps> = ({ item, className }) => {
  const { label, description, internalUrl } = item

  if (!internalUrl) return null;

  return (
    <Link to={internalUrl} className={classnames(className, styles.itemWithDesc)}>
      <h6>{label}</h6>
      <p>{description}</p>
    </Link>
  )
}

const MenuItemWithSubitems: FC<MenuItemProps> = ({ item, className }) => {
  const { label, internalUrl, children } = item

  return (
    <div className={classnames(className, styles.menuSubcategory)}>
      {internalUrl ? (
        <Link to={internalUrl} className={styles.menuSubcategoryTitle}>
          {label}
        </Link>
      ) : (
        <p className={styles.menuSubcategoryTitle}>{label}</p>
      )}

      {children && (
        <div className={styles.menuSubcategoryItems}>
          {children.map((element, index) => {
            if (!element.internalUrl) return null

            const key = `nav-${index}-${element.label.replace(' ', '-')}`
            return (
              <Link to={element.internalUrl} key={key} className={styles.menuSubitem}>
                {element.isItemNew ? <span className={styles.menuSubitemNew}>NEW</span> : null}
                {element.label}
              </Link>
            )
          })}
        </div>
      )}
    </div>
  )
}

export const MenuExpandable: FC<MenuExpandableProps> = props => {
  const { className, item, isOpen, setIsOpen, id } = props
  const [maxHeight, setMaxHeight] = useState('100px');
  const match = useMatch(`${item.internalUrl}/*` || '/fake-path')
  const areItemsWithDescription =
    item.children && item.children.length > 0 && item.children.some((child) => child.description)

  const childrenWrapperRef = useRef<HTMLDivElement>(null);
  const menuHeaderRef = useRef<HTMLDivElement>(null);

  const recommendationType = item.recommendation[0]?._type

  const recommendationSectionData: RecommendationData = {
    recommendations: item.recommendation,
    sectionTitle: item.recommendationSectionTitle,
    shouldDisplayBrowseAllButton: item.shouldDisplayBrowseAllButton,
    browseAllButtonLabel: item.browseAllButtonLabel,
    browseAllButtonUrl: item.browseAllButtonUrl,
  }

  useEffect(() => {
    if (isOpen) {
      const scrollHeight = childrenWrapperRef.current?.scrollHeight;
      if (!scrollHeight) return;
      setMaxHeight(`${scrollHeight + 32}px`); // margin included

      setTimeout(() => {
        if (menuHeaderRef.current) {
          menuHeaderRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }, 600); // wait until the max-height animation is finished

    } else {
      setMaxHeight('100px');
    }
  }, [isOpen]);

  const handleMouse = (id: string | null) => {
    setIsOpen(id)
  }

  const handleTouch = () => {
    if (isOpen) {
      setIsOpen(null)
    } else {
      setIsOpen(id)
    }
  }

  return (
    <div
      className={classnames(className, styles.container, { [styles.active]: !!match }, { [styles.containerOpen]: isOpen })}
      onMouseEnter={() => handleMouse(id)}
      onMouseLeave={() => handleMouse(null)}
      onTouchStart={() => handleTouch()}
      style={{ maxHeight }}
    >
      <div ref={menuHeaderRef}></div>
      {item.internalUrl || item.externalUrl ? (
        <MenuLink
          className={classnames(styles.label, {
            [styles.withChildren]: item.children && item.children.length > 0,
          })}
          item={item}
        />
      ) : (
        <div
          className={classnames(styles.label, {
            [styles.withChildren]: item.children && item.children.length > 0,
          })}
        >
          {item.label}
        </div>
      )}

      <ChevronIcon className={styles.chevron} />

      <div
        className={styles.childrenWrapper} 
        onTouchStart={(e) => e.stopPropagation()} 
        ref={childrenWrapperRef}>
        <div className={styles.childrenListWrapper}>
          <div
            className={classnames(styles.childrenList, {
              [styles.withDescription]: areItemsWithDescription,
              [styles.withSubitems]: !areItemsWithDescription,
            })}
          >
            {areItemsWithDescription
              ? item.children?.map((item, index) => {
                  const key = `nav-${index}-${item.label.replace(' ', '-')}`
                  return <MenuItemWithDescription item={item} key={key} />
                })
              : item.children?.map((item, index) => {
                  const key = `nav-${index}-${item.label.replace(' ', '-')}`
                  return <MenuItemWithSubitems item={item} key={key} />
                })}
          </div>
        </div>

        {item.shouldDisplayBottomButton ? (
          <a href={item.bottomButtonUrl!} className={styles.bottomLink}>
            {item.bottomButtonLabel} <IconArrow />
          </a>
        ) : null}

        <div className={styles.sectionRecommendations}>
          {recommendationType === 'quoteWithAuthorAndLink' && (
            <RecommendationQuote data={recommendationSectionData} />
          )}
          {recommendationType === 'recommendedArticlesList' && (
            <RecommendationArticles data={recommendationSectionData} />
          )}
          {recommendationType === 'review' && (
            <RecommendationReview data={recommendationSectionData} />
          )}
        </div>
      </div>
    </div>
  )
}
